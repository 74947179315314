<template>
  <!-- prettier-ignore -->
  <index-content>
    <Tabs v-model="tab" name="customerTransaction" :animated="false" @on-click="changeTab">
      <template slot="extra">
        <div class="n-button-box">
          <nb-download v-if="tab === 'active'" :url="CUSTOMER_TRANSACTION_ACTIVE_CSV_URL" :params="activeCondition" />
          <nb-download v-if="tab === 'history'" :url="CUSTOMER_TRANSACTION_HISTORY_CSV_URL" :params="historyCondition" :disabled="hisCsvDisabled" />
        </div>
      </template>
      <TabPane tab="customerTransaction" name="active" :label="this.$t('label.active')" class="pt-2">
        <customer-customer-transaction-active :condition="activeCondition" @records="changeActiveRecords" />
      </TabPane>
      <TabPane tab="customerTransaction" name="history" :label="this.$t('label.history')" class="pt-2">
        <customer-customer-transaction-history :condition="historyCondition" @records="changeHistoryRecords" @searchedHasError="v => searchedHasError = v" />
      </TabPane>
    </Tabs>
  </index-content>
</template>

<script>
import CustomerView from './view';
import { CUSTOMER_TRANSACTION_ACTIVE_CSV_URL, CUSTOMER_TRANSACTION_HISTORY_CSV_URL } from '@/api/customer/customer-customer-transaction';
import CustomerCustomerTransactionActive from './CustomerCustomerTransactionActive';
import CustomerCustomerTransactionHistory from './CustomerCustomerTransactionHistory';
import { isEmpty } from '@/helpers/utils';

export default CustomerView.extend({
  name: 'CustomerCustomerTransaction',
  components: { CustomerCustomerTransactionActive, CustomerCustomerTransactionHistory },
  data() {
    return {
      tab: 'active',
      activeCondition: {
        companyId: null,
        customerId: null,
        accountId: null,
        type: 0,
        status: 0,
        token: null,
        from: this.sod,
        to: this.eod,
        isHistory: false,
      },
      historyCondition: {
        companyId: null,
        accountId: null,
        from: this.halfYearAgo,
        to: this.eod,
        isHistory: true,
      },
      activeRecords: [],
      historyRecords: [],
      CUSTOMER_TRANSACTION_ACTIVE_CSV_URL,
      CUSTOMER_TRANSACTION_HISTORY_CSV_URL,
      searchedHasError: true,
    };
  },
  computed: {
    hisCsvDisabled() {
      return isEmpty(this.historyCondition.accountId) || this.searchedHasError;
    },
  },
  methods: {
    changeActiveRecords(records) {
      this.activeRecords = records;
      if (this.tab === 'active') this.changeTab('active');
    },
    changeHistoryRecords(records) {
      this.historyRecords = records;
      if (this.tab === 'history') this.changeTab('history');
    },
    changeTab(tab) {
      this.records = tab === 'active' ? this.activeRecords : this.historyRecords;
    },
  },
});
</script>

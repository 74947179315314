import { $fetch, $post } from '@/services/axios';

export const CUSTOMER_TRANSACTION_ACTIVE_CSV_URL = `customer/transactions/csv`;
export const CUSTOMER_TRANSACTION_HISTORY_CSV_URL = `customer/transactions/history/csv`;

export function fetch(params) {
  return $fetch(`customer/transactions`, params);
}

export function history(params) {
  return $fetch(`customer/transactions/history`, params);
}

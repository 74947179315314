<template>
  <!-- prettier-ignore -->
  <table class="table table-bordered table-hover table-striped text-center n-table">
    <thead>
    <tr>
      <th class="n-table-id">{{ $t('label.id') }}</th>
      <th class="company">{{ $t('label.company') }}</th>
      <th>{{ $t('label.customerId') }}</th>
      <th>{{ $t('label.sourceAccountId') }}</th>
      <th class="category">{{ $t('label.sourceCategoryId') }}</th>
      <th class="text-right">{{ $t('label.amount') }}</th>
      <th class="status">{{ $t('label.status') }}</th>
      <th class="n-table-timestamp">{{ $t('label.inputTime') }}</th>
      <th v-table-action>{{ $t('label.action') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="row in records" :key="row.id">
      <td>{{ row. id }}</td>
      <td v-selection:[`company.company`]="row.companyId"></td>
      <td class="n-ellipsis">
        <n-modal-link name="customer.profile" :value="row.customerId" />
      </td>
<!--      <td>
        <n-modal-link name="customer.account" :value="row.accountId" />
      </td>-->
      <td>{{ row.sourceAccountId }}</td>
      <td>{{ row.sourceCategoryId }}</td>
      <td class="n-text-right">
        <n-number :value="row.amount" :scale="row.currencyScale" :currency="row.currency" />
      </td>
      <td v-enums:CustomerTransactionStatus="row.status"></td>
      <td>{{ row.inputTime | datetime }}</td>
      <td class="n-button-box">
        <nb-modal @on-click="openModal(row)" />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ModalCustomerTransaction from './ModalCustomerTransaction';
import { createModal } from '@/helpers/modal';

export default {
  name: 'CustomerTransactionTable',
  props: {
    isActive: Boolean,
    records: Array,
  },
  methods: {
    openModal(model = {}) {
      createModal(ModalCustomerTransaction, { model, on: this });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  th.status, th.company {
    width: 7rem;
  }

  th.rw-action {
    width: 4rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>

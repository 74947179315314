<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-id v-model="model.id" label="id" disabled />
        <n-field-id v-model="model.sourceId" label="sourceId" disabled />
      </n-row-col2>
      <n-row-col2>
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="!isCreate || disabled" />
        <n-field-id v-model="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-id v-model="model.sourceAccountId" field-name="sourceAccountId" label="sourceAccountId" :error="error.sourceAccountId" :disabled="isEdit || disabled" />
        <n-field-id v-model="model.sourceCategoryId" field-name="sourceCategoryId" label="sourceCategoryId" :error="error.sourceCategoryId" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.status" :error="error.status" enum-name="CustomerBudgetStatus" label="status" :disabled="disabled" />
        <n-field-decimal v-model="model.amount" label="amount" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- occurDate -->
        <n-field-date v-model="model.occurDate" label="occurDate" disabled />
        <!-- occurTime -->
        <n-field-datetime v-model="model.occurTime" label="occurTime" disabled />
      </n-row-col2>
      <n-row-col1>
        <n-field-text :value="model.note" label="note" disabled />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalCustomerTransaction',
  components: { NModal },
});
</script>
